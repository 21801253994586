#overlay-container {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 2000;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-content: center;
}

.overlay-item-picture {
	text-align: center;
}

.overlay-item-picture > img {
	max-width: 100%;
	max-height: 80vh;
}

.thumbnail-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	min-height: 60px;
	gap: 30px 20px;
}

.thumbnail-container::after {
	content: '';
	flex: auto;
}

.input-picture-upload {
	max-width: 150px;
	height: 150px;
	background-color: black;
}
